import React, { useState, useEffect, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
	BookmarkAltIcon,
	CalendarIcon,
	ChartBarIcon,
	CursorClickIcon,
	MenuIcon,
	PhoneIcon,
	PlayIcon,
	RefreshIcon,
	ShieldCheckIcon,
	SupportIcon,
	ViewGridIcon,
	XIcon,
	UserIcon,
	MailIcon,
} from "@heroicons/react/outline";
import { FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";

export default function NavBar({showScroll}) {
	const links = [
		{
			name: "About",
			href: "#about",
			icon: UserIcon,
		},
		{
			name: "Tools",
			href: "/tools",
			icon: CursorClickIcon,
		},
		{
			name: "Projekte",
			href: "#projects",
			icon: ViewGridIcon,
		},
	];

	return (
		<div>
			<Popover className="relative bg-white">
				<div
					className={`flex justify-between py-2 px-12 md:px-0 md:justify-around items-center fixed top-0 w-full border-b-2 border-transparent transition-all ${showScroll && "scrolled bg-white bg-opacity-80 border-black border-opacity-10"} z-10`}
				>
					<div className="flex items-center my-2">
						{/* Logo */}
						<div className="">
							<Link
								to="/"
								rel="noopener noreferrer"
								className="flex items-center md:hover:scale-110 duration-150 md:mr-8"
							>
								<img
									src={logo}
									className="w-8 mr-2">
								</img>
								<p className="header-text text-gray-800 text-2xl relative after:absolute after:content-[''] hover:after:w-full after:w-0 after:h-1 after:bg-orange-200 after:bottom-0 after:left-0 after:transition-all after:drop-shadow-glow-2">hashicode</p>
							</Link>
						</div>
						{/* Main Nav */}
						<Popover.Group as="nav" className="hidden md:flex space-x-10">
							<div className="flex items-center space-x-4 font-semibold">
								{links.map((item) => (
									<Link
										key={item.name}
										to={item.href}
										className="text-gray-800 hover:text-black relative after:absolute after:content-[''] hover:after:w-full after:w-0 after:h-1 after:bg-orange-200 after:bottom-0 after:left-0 after:transition-all after:drop-shadow-glow-2"
									>
										{item.name}
									</Link>
								))}
							</div>
						</Popover.Group>
					</div>
					{/* Right Nav */}
					<div>
						<div className="-mr-2 -my-2 md:hidden">
							<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
								<span className="sr-only">Open menu</span>
								<MenuIcon className="h-6 w-6" aria-hidden="true" />
							</Popover.Button>
						</div>
						<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
							<a
								href="https://github.com/Hashico-de"
								target="_blank"
								rel="noreferrer noopener"
								className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-2 py-2 border border-transparent rounded-full shadow-md hover:shadow-lg hover:border-black bg-white text-base font-medium text-black"
							>
								<FaGithub
									className="h-6 w-6 text-gray-800"
									aria-hidden="true"
								/>
							</a>
						</div>
					</div>
				</div>

				<Transition
					as={Fragment}
					enter="duration-200 ease-out"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="duration-100 ease-in"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<Popover.Panel
						focus
						className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-20"
					>
						<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
							<div className="pt-5 pb-6 px-5">
								<div className="flex items-center justify-between">
									<div>
										<img
											className="h-8 w-auto"
											src="https://avatars.githubusercontent.com/u/96670941?v=4"
											alt="Workflow"
										/>
									</div>
									<div className="-mr-2">
										<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
											<span className="sr-only">Close menu</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
									</div>
								</div>
								<div className="mt-6">
									<nav className="grid gap-y-8">
										{links.map((item) => (
											<Link
												key={item.name}
												to={item.href}
												className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
											>
												<item.icon
													className="flex-shrink-0 h-6 w-6 text-orange-500"
													aria-hidden="true"
												/>
												<span className="ml-3 text-base font-medium text-gray-900">
													{item.name}
												</span>
											</Link>
										))}
									</nav>
								</div>
							</div>
							<div className="py-6 px-5 space-y-6">
								<div>
									<a
										href="https://github.com/Hashico-de"
										target="_blank"
										rel="noreferrer"
										className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-black bg-orange-400 hover:bg-orange-500"
									>
										<FaGithub className="h-6 w-6" aria-hidden="true" />
									</a>
								</div>
							</div>
						</div>
					</Popover.Panel>
				</Transition>
			</Popover>
			{/* {children}
			<div className="text-center m-4 mt-12 text-black">
				<p>
					Made with much ❤️ by
					<a
						href="https://derzerstampfer.omegalul.co/"
						target="_blank"
						rel="noreferrer"
					>
						{" "}
						DerZerstampfer
					</a>{" "}
					and{" "}
					<a href="https://chrisbrandt.dev/" target="_blank" rel="noreferrer">
						Kostari
					</a>
				</p>
			</div> */}
		</div>
	);
}
