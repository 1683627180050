import { useCallback, useState, useEffect, useRef } from "react";

import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/src/locomotive-scroll.scss";

const useToggle = (initialState = false) => {
	const [state, setState] = useState(initialState);
	const toggle = useCallback(() => setState((state) => !state), []);

	return [state, toggle, setState];
};

const useWindowSize = (onResize) => {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});
	useEffect(() => {
		const handleResize = (e) => {
			if (onResize !== undefined) onResize(e);
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return windowSize;
};

const useScroll = (onScroll) => {
	const [scroll, setScroll] = useState({
		scrollY: undefined,
		scrollX: undefined,
	});

	useEffect(() => {
		const handleScroll = (e) => {
			e = { ...e, scrollY: window.pageYOffset, scrollX: window.pageXOffset };
			if (onScroll !== undefined) onScroll(e);
			setScroll({
				scrollY: window.pageYOffset,
				scrollX: window.pageXOffset,
			});
		};

		window.addEventListener("scroll", handleScroll);
		handleScroll();
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);
	return scroll;
};

const useOnScreen = (props) => {
	const {ref, single = false, rootMargin = "0px", threshold = 0, root} = props;
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIntersecting(entry.isIntersecting);
				if (single && entry.isIntersecting) {
					observer.unobserve(entry.target);
				}
			},
			{ rootMargin, threshold, root }
		);

		if (ref.current) {
			observer.observe(ref.current);
		}
		
		return () => observer.unobserve(ref.current);
	}, []);

	return isIntersecting;
};

const useResize = (props) => {
	const {ref, onResize} = props;

	useEffect(() => {
		const observer = new ResizeObserver(
			([entry]) => {
				if (onResize !== undefined) onResize(entry);
			}
		);

		if (ref && ref.current) {
			observer.observe(ref.current);
		}
		
		return () => observer.unobserve(ref.current);
	}, []);
}

const useLocomotiveScroll = (ref) => {
	const locomotiveScrollRef = useRef();

	useEffect(() => {
		if (ref.current) {
			locomotiveScrollRef.current = new LocomotiveScroll({
				el: ref.current,
				smooth: true,
			});
		}

		return () => {
			locomotiveScrollRef.current.destroy();
		}
	}, [ref]);
	return [locomotiveScrollRef];
}

export { useToggle, useWindowSize, useScroll, useOnScreen, useResize, useLocomotiveScroll };
