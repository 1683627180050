import React, { useState } from "react";

function Input(props) {
	let { type, onChange, onFocus, min, max, label, value } = props;
	const empty = value.length === 0;
	return (
		<div className="flex flex-col items-start relative">
			<label className={`opacity-0`}>{label}</label>
			<div className={`relative before:content-[''] before:w-full before:h-[1px] before:bottom-0 before:bg-orange-200 before:absolute`}>
				<input
					type={type}
					value={value}
					className="peer p-2 shadow-inset bg-slate-50 outline-0"
					min={min}
					max={max}
					onChange={onChange}
					onFocus={onFocus}
				/>
				<p className={`peer-focus:-translate-y-8 peer-focus:-translate-x-2 pointer-events-none peer-focus:text-black absolute top-2 left-2 duration-300 ${empty ? "text-gray-500" : "-translate-y-8 -translate-x-2 text-black"}`}>{label}</p>
			</div>
		</div>
	);
}
export default Input;
