import React from "react";

export default function Button(props) {
	const { text, href, onClick, className, glow, secondary } = props;
	return (
		<a
			href={href}
			onClick={onClick}
			className={`flex justify-center items-center ${
				glow && "glow"
			} font-bold select-none cursor-pointer px-8 py-4 ${
				!secondary && "bg-orange-200 hover:bg-orange-175"
			} ${
				secondary && "bg-blue-200 hover:bg-blue-175"
			} rounded text-white duration-300 ${className}`}
		>
			{text}
		</a>
	);
}
