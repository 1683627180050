import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import Tools from "./pages/Tools";

import { SingleBadge } from "react-easy-badges";
import { useToggle } from "./hooks/Hooks";

import Snowfall from "react-snowfall";

import InvestmentCalculator from "./tools/InvestmentCalculator";
import QrCodeGenerator from './tools/QrCodeGenerator';

export default function Router() {
	let [isSnowing, toggleSnowing, setSnowing] = useToggle(false);
	let [isDecember, setDecember] = useState(false);

	const toolList = [
			{
				urlname: 'investment-calculator',
				tag: <InvestmentCalculator />,
				name: 'Investment Calculator',
				description: 'Calculate the estimated return of your investments.'
			},
			{
				urlname: 'qr-generator',
				tag: <QrCodeGenerator />,
				name: 'QR-Code Generator',
				description: 'Generate custom QR codes.'
			}
		];

	useEffect(() => {
		const december = new Date().getMonth() === 11;
		setDecember(december);
		setSnowing(december);
	}, []);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Landing toolList={toolList} />} />
				<Route path="/tools" element={<Tools toolList={toolList} />} />
				{
					toolList.map((item, index) => (
						<Route path={`/tools/${item.urlname}`} element={item.tag} />
					))
				}
			</Routes>
			<SingleBadge
				text="Buy us a coffee!"
				ribbonColor="var(--orange-200)"
				badgeColor="black"
				height="64"
				css="box-shadow: -4px 4px 15px rgba(0 0 0 / .25)"
			/>
			{isDecember && <SingleBadge
						text="Toggle Snowfall"
						ribbonColor="var(--orange-200)"
						badgeColor="black"
						height="64"
						emoji="❄️"
						position="left"
						css="box-shadow: 4px 4px 15px rgba(0 0 0 / .25)"
						onClick={toggleSnowing}
					/>}
			{isSnowing && <Snowfall />}
		</BrowserRouter>
	);
}
