import { useState, useEffect } from 'react';
import axios from "axios";
import { Buffer } from 'buffer';

import Input from "../components/Input";
import Tool from "../layouts/Tool";
import Button from "../components/Button"

export default function QrCodeGenerator() {
	const [value, setValue] = useState('');
	const [image, setImage] = useState();

  const generateQrCode = () => {
    const options = {
      method: 'POST',
      url: 'https://qrcode-generator1.p.rapidapi.com/generate',
      headers: {
        'content-type': 'application/json',
        'x-rapidapi-host': 'qrcode-generator1.p.rapidapi.com',
        'x-rapidapi-key': 'c95c9ad0damsh292b34d95ceef29p10832djsn1aae0dcde50f',
      },
      responseType: 'arraybuffer',
      data: {value, format: 'png', margin: 1, size: 5}
    };
    
    axios.request(options).then(async function (response) {
      setImage("data:image/png;base64, " + Buffer.from(response.data, 'binary').toString('base64'));
    }).catch(function (error) {
      console.error(error);
    });
  };

  return (
    <Tool title="QR Code Generator">
      <p>Qr</p>
      <Input
        type="text"
        label="Value"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <Button onClick={generateQrCode} text="Generate">
      </Button>
      <img src={image && image} lazy />
    </Tool>
  )
}
