import { useState, useEffect } from "react";
import Chart from "react-apexcharts";

import Input from "../components/Input";
import Tool from "../layouts/Tool";

export default function InvestmentCalculator() {
	const [y, setY] = useState(0);
	const [m, setM] = useState(0);
	const [start, setStart] = useState(0);
	const [addpermonth, setAddpermonth] = useState(0);
	const [percperyear, setPercperyear] = useState(0);

	const result = etfRechner(parseInt(y), parseInt(m), parseInt(start), parseInt(addpermonth), parseInt(percperyear));

	const handleFocus = (e) => e.target.value === 0 && e.target.select();

	var series = [start | 0, result.act, result.win];

	var options = {
		chart: {
			type: 'pie',
		},
		labels: ["Starting Amount", "Total Contributions", "Total Interest"],
		dataLabels: {
			enabled: true,
		},
		fill: {
			type: 'gradient',
		},
		legend: {
			formatter: function(val, opts) {
				return val + " - " + currencyFormat(opts.w.globals.series[opts.seriesIndex])
			}
		},
		fill: {
			colors: ['#a84414', '#ff1212', '#56acbd'],
		},
		colors: ['#a84414', '#ff1212', '#56acbd'],
		responsive: [{
			breakpoint: 99999999,
			options: {
				chart: {
					width: 380
				},
				legend: {
					position: 'bottom'
				}
			}
		}]
	}

	return (
		<Tool title="Investment Calculator">
			<p className="text-4xl font-semibold pb-2">Investment Calculator</p>
			<div className="flex flex-row flex-wrap justify-center items-center md:p-6 md:shadow-lg md:rounded-2xl bg-orange-125">
				<div className="">
					<Input
						type="number"
						label="Years"
						min="0"
						onFocus={handleFocus}
						value={y}
						onChange={(e) => {
							setY(e.target.value);
						}}
					/>
					<Input
						type="number"
						label="Months"
						onFocus={handleFocus}
						value={m}
						onChange={(e) => {
							setM(e.target.value);
						}}
					/>
					<Input
						type="number"
						label="Start"
						min="0"
						onFocus={handleFocus}
						value={start}
						onChange={(e) => {
							setStart(e.target.value);
						}}
					/>
					<Input
						type="number"
						label="Deposite or Withdrawle per Month"
						onFocus={handleFocus}
						value={addpermonth}
						onChange={(e) => {
							setAddpermonth(e.target.value);
						}}
					/>
					<Input
						type="number"
						label="%PerYear (Gets added per Month)"
						onFocus={handleFocus}
						value={percperyear}
						min={-100}
						onChange={(e) => {
							setPercperyear(e.target.value);
						}}
					/>
				</div>
				<div className="flex flex-col justify-between h-full gap-y-4">
					<div>
						<div className="flex flex-row justify-between font-bold border-b-2 border-black">
							<p>End Balance:</p>
							<p>{currencyFormat(result.end)}</p>
						</div>
						<div className="flex flex-row justify-between">
							<p>Start Amount:</p>
							<p>{currencyFormat(start | 0)}</p>
						</div>
						<div className="flex flex-row justify-between">
							<p>Total Contributions:</p>
							<p>{currencyFormat(result.act)}</p>
						</div>
						<div className="flex flex-row justify-between">
							<p>Total Interest:</p>
							<p>{currencyFormat(result.win)}</p>
						</div>
					</div>
					<div>
						<Chart options={options} series={series} type="donut" width={380} />
					</div>
				</div>
			</div>
		</Tool>
	);
}

const twoDezimals = (num) => parseFloat((Math.round(num * 100) / 100).toFixed(2));

function etfRechner(y, m, start, addpermonth, percperyear) {
	start = start | 0;
	percperyear = percperyear | 0;

	let mny = start;
	let act = 0;
	for (let index = 0; index < m + y * 12; index++) {
		mny *= (percperyear / 12 + 100) / 100;
		mny += addpermonth;
		act += addpermonth;
	}

	// mny = parseInt(mny.toFixed(2));
	// const times = parseInt((mny / act).toFixed(2));
	// act = parseInt(act.toFixed(2));
	// const win = parseInt((mny - act).toFixed(2));

	const times = mny / act;
	const win = mny - act - start;

	return {
		end: twoDezimals(mny),
		times: twoDezimals(times),
		act: twoDezimals(act),
		win: twoDezimals(win),
	};
}

function currencyFormat(num) {
	return "€ " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
