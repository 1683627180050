import React, { useRef, useEffect, useState } from "react";

function Cursor() {
	const secondaryCursor = useRef();
	const positionRef = useRef({
		mouseX: 0,
		mouseY: 0,
		destinationX: 0,
		destinationY: 0,
		distanceX: 0,
		distanceY: 0,
		key: -1,
	});
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		document.addEventListener("mousemove", (event) => {
			const { clientX, clientY } = event;

			const mouseX = clientX;
			const mouseY = clientY;

			if (secondaryCursor.current.clientWidth) {
				positionRef.current.mouseX = mouseX - secondaryCursor.current.clientWidth / 2;
			}
			if (secondaryCursor.current.clientHeight) {
				positionRef.current.mouseY = mouseY - secondaryCursor.current.clientHeight / 2;
			}
		});

		document.addEventListener("mouseleave", (e) => {
			setVisible(false);
		})

		document.addEventListener("mouseenter", (e) => {
			setVisible(true);
		})

		return () => {};
	}, []);

	useEffect(() => {
		const followMouse = () => {
			positionRef.current.key = requestAnimationFrame(followMouse);
			const { mouseX, mouseY, destinationX, destinationY, distanceX, distanceY } = positionRef.current;
			if (!destinationX || !destinationY) {
				positionRef.current.destinationX = mouseX;
				positionRef.current.destinationY = mouseY;
			} else {
				positionRef.current.distanceX = (mouseX - destinationX) * 0.08;
				positionRef.current.distanceY = (mouseY - destinationY) * 0.08;
				if (Math.abs(positionRef.current.distanceX) + Math.abs(positionRef.current.distanceY) < 0.1) {
					positionRef.current.destinationX = mouseX;
					positionRef.current.destinationY = mouseY;
				} else {
					positionRef.current.destinationX += distanceX;
					positionRef.current.destinationY += distanceY;
				}
			}
			if (secondaryCursor && secondaryCursor.current)
				secondaryCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
		};
		followMouse();
	}, []);

	return <div ref={secondaryCursor} className={`z-[200] pointer-events-none fixed mix-blend-difference`}>
        <div className={`z-[199] bg-orange-200 rounded-full w-10 h-10 transition-transform ${visible ? "scale-100" : "scale-0"}`}></div>
    </div>;
}

export default Cursor;
