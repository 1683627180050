import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion"

import NavBar from "./NavBar";
import Cursor from "./Cursor";
import { useLocomotiveScroll, useScroll } from "../hooks/Hooks";

function Page({ children }) {
	const ref = useRef();
	const [locomotiveScroll] = useLocomotiveScroll(ref);
	const [showScroll, setShowScroll] = useState(false);
	const [locomotiveStart, setLocomotive] = useState(false);

	useEffect(() => {
		if (locomotiveScroll.current) {
            locomotiveScroll.current.on("scroll", (e) => {
                setShowScroll(e.scroll.y >= 10);
            })
		}
	}, [locomotiveScroll]);

	useEffect(() => {
		if (locomotiveStart) {
			locomotiveScroll.current.update();
		}
	}, [locomotiveStart])

	// useScroll((e) => {
	// 	setShowScroll(e.scrollY >= 10);
	// })
	return (
		<div>
			<Cursor />
			<NavBar showScroll={showScroll}/>
			<div ref={ref} data-scroll-container>
				<motion.div initial={{
					opacity: 0
				}} animate={{
					paddingTop: "3rem",
					opacity: 1,
					transition: {duration: 1},
				}} onAnimationComplete={() => setLocomotive(true)}>
					{children}
				</motion.div>
			</div>
		</div>
	);
}

export default Page;
