import React, { useEffect, useState } from "react";
import Page from "../components/Page";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const wordAnimationContainer = {
	animate: {
		transition: {
			delayChildren: 0.1,
			staggerChildren: 0.1,
		},
	},
};

const wordAnimation = {
	initial: { y: 200 },
	animate: {
		y: 0,
		transition: {
			type: "spring",
			duration: 1,
		},
	},
};

const cardContainer = {
	animate: {
		transition: {
			delayChildren: 0.1,
			staggerChildren: 0.1,
		},
	},
};

export default function Landing(props) {
	const { toolList } = props;
	const [oldtitle, setOldtitle] = useState(document.title);
	const [toolListEdited, setToolListEdited] = useState([]);


	useEffect(() => {
		document.title = `Hashicode`;
		return () => {
			document.title = oldtitle;
		};
	}, []);

	useEffect(() => {
		let arr = [];
		for (let index = 0; index < toolList.length; index++) {
			const element = toolList[index];
			arr.push(element);
			if (index % 2) {
				arr.push({
					urlname: "",
					tag: null,
					name: "",
					description: "",
				});
				arr.push({
					urlname: "",
					tag: null,
					name: "",
					description: "",
				});
			} else {
				arr.push({
					urlname: "",
					tag: null,
					name: "",
					description: "",
				});
			}
		}
		setToolListEdited(arr);
	}, [toolList]);

	return (
		<Page>
			<div id="title-section">
				<div className="text-center pt-28 flex flex-col items-center">
					<div className="xl:text-giant md:text-9xl text-8xl text-left font-bold w-4/5 flex flex-col items-center select-none">
						<span className="relative">
							<span className="animate-fade-1 gradient-text glow-text">Tools</span>
							<span className="animate-fade-2 absolute inset-0">Tools</span>
						</span>
						for
						<span className="relative">
							<span className="animate-fade-2 gradient-text-secondary glow-text-secondary">You.</span>
							<span className="animate-fade-1 absolute inset-0">You.</span>
						</span>
					</div>
					<AnimatedWords
						className="mt-8 w-4/6 font-bold"
						text="Find a wide collection of tools for your convenience."
					/>
					<AnimatedWords
						className="mt-8 w-4/6"
						text="Convert currencies or calculate the profit of your investment and many more options!"
					/>
				</div>
			</div>
			<motion.div
				animate={{
					rotate: 360,
				}}
				transition={{
					duration: 40,
					repeat: Infinity,
					ease: "linear"
				}}
				className="border-4 border-dashed rounded-[50%] -z-50 absolute border-black -left-1/3 w-[60vw] h-[60vw]"
				// data-scroll
				// data-scroll-direction="vertical"
				// data-scroll-speed="-2"
			></motion.div>
			<motion.div
				id="card-section"
				className="gap-4 grid grid-cols-1 auto-rows-auto p-16 mt-36 md:grid-cols-2 lg:grid-cols-4 lg:grid-rows-2 bg-gray-50"
				variants={cardContainer}
				initial="initial"
				whileInView="animate"
				viewport={{ once: true, amount: 0.1 }}
			>
				<motion.h2
					className="col-span-1 md:col-span-2 font-bold text-4xl w-[28rem] pb-10"
					initial={{ opacity: 0, x: -100 }}
					whileInView={{
						opacity: 1,
						x: 0,
						transition: {
							duration: 0.4,
						},
					}}
					viewport={{ once: true }}
				>
					We provide you with self{" "}
					<span className="bg-orange-150 border-b-2 border-dashed border-black">developed</span> Tools. We love coding,
					helping others and love working with them.
				</motion.h2>
				{toolListEdited &&
					toolListEdited.map((card, index) => (
						<Card
							data-hover
							title={card.name}
							text={card.description}
							index={index}
							key={index}
							urlname={card.urlname}
						/>
					))}
			</motion.div>
		</Page>
	);
}

const AnimatedWords = (props) => {
	const { text, className } = props;
	const words = text.split(" ");
	return (
		<motion.div className={`overflow-hidden relative ${className}`}>
			<motion.span variants={wordAnimationContainer} initial="initial" animate="animate" className="overflow-hidden">
				{words &&
					words.map((word, index) => (
						<motion.span key={index} className="inline-block mx-0.5 md:text-xl" variants={wordAnimation}>
							{word}
						</motion.span>
					))}
			</motion.span>
		</motion.div>
	);
};

const Card = (props) => {
	let navigate = useNavigate();
	const { title, text, index, urlname } = props;
	const empty = title === "" && text === "";

	const cardAnimation = {
		initial: { rotate: index % 2 === 0 ? 2 : -2, opacity: 0 },
		animate: {
			rotate: 0,
			opacity: 1,
			transition: {
				duration: 1,
			},
		},
	};

	return (
		<motion.div data-hover variants={cardAnimation} onClick={() => urlname && navigate(`/tools/${urlname}`)}>
			<motion.div
				className={`${empty ? "hidden md:block bg-gray-200" : "bg-orange-150 cursor-pointer"} rounded-xl h-72 p-10`}
				whileHover={{
					rotate: index % 2 === 0 ? 2 : -2,
				}}
			>
				<h1 className="font-bold text-3xl">{title}</h1>
				<p className="text-lg pt-2">{text}</p>
			</motion.div>
		</motion.div>
	);
};
