import React, { useEffect, useState } from "react";
import Page from "../components/Page";
import { PresentationChartLineIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export default function Tools(props) {
	const { toolList } = props;
	const [oldtitle, setOldtitle] = useState(document.title);

	useEffect(() => {
		document.title = `Hashicode | Tools`;
		return () => {
			document.title = oldtitle;
		};
	}, []);

	const splitsContainer = {
		animate: {
			transition: {
				delayChildren: .5,
				staggerChildren: 0.5,
			},
		},
	};

	return (
		<Page>
			<div className="text-center pt-28 flex flex-col items-center">
				<p className="xl:text-giant md:text-9xl text-8xl text-left font-bold w-4/5 flex flex-col items-center select-none">
					Tools
				</p>
			</div>
			<motion.div className="mt-[73px]" variants={splitsContainer} initial="initial" animate="animate">
				{toolList.map((item, index) => (
					<ToolSplit text={item.name} to={item.urlname} description={item.description} mirror={index % 2} />
				))}
			</motion.div>
		</Page>
	);
}

const ToolSplit = (props) => {
	let navigate = useNavigate();
	const { mirror, text, to, icon, description } = props;
	return (
		<motion.div
			className={`tool-clip w-full h-72 -my-[73px] flex justify-center items-center duration-300 ${
				mirror ? "flex-row-reverse" : ""
			} hover:cursor-pointer`}
			onClick={() => navigate(`/tools/${to}`)}
		>
			<motion.div
				className="bg-amber-200 w-full h-full relative flex justify-center items-center"
				initial={{ x: mirror ? 100 : -100 }}
				whileInView={{
					x: 0,
					transition: {
						duration: 1,
					},
				}}
				viewport={{ once: true }}
			>
				{/* <img className="saturate-0 hover:saturate-100 duration-300 absolute left-1/2 top-[10%] -translate-x-1/2" src="logo192.png" alt="" /> */}
				{/* <icon
					className="saturate-0 hover:saturate-100 duration-300 absolute left-1/2 top-[10%] -translate-x-1/2"
				/> */}
				<p className="duration-300">{description && description}</p>
			</motion.div>
			<motion.div
				className="bg-orange-200 w-full h-full relative flex justify-center items-center"
				initial={{ x: mirror ? -100 : 100 }}
				whileInView={{
					x: 0,
					transition: {
						duration: 1,
					},
				}}
				viewport={{ once: true }}
			>
				<p className="text-4xl font-bold text-white">{text}</p>
			</motion.div>
		</motion.div>
	);
};
