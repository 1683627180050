import React, { useEffect, useState } from "react";
import Page from "../components/Page"
import Button from "../components/Button";

export default function Tool(props) {
  const { children, title } = props;
  const [oldtitle, setOldtitle] = useState(document.title);

	useEffect(() => {
		if (title) {
			document.title = `Hashicode | ${title}`;
		}
    return () => {
      document.title = oldtitle;
    };
	}, []);

  return (
    <Page>
      <div
        className="text-center pt-28 flex flex-col items-center"
      >
        {children}
			  <p>Custom Tools developed by the Hashicode Team :)</p>
      </div>
		</Page>
  )
}
